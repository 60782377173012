<template>
  <v-row style="background: white;">
    <v-col cols="12" :class="cardClass" v-for="(timeline, index) in timelines"
      :key="index"
    >
      <v-card
        color="#F8F8FF"
        class="timeline-card"
        @click="viewClickEvent(timeline)"
      >
        <v-row align="center">
          <v-col cols="10" class="timeline-header pl-5">
            <v-row align="center" style="padding-left: 0">
              <v-col cols="2">
                <div class="timeline-header-avatar">
                  <img :src="timeline.origemthumb"/>
                </div>
              </v-col>
              <v-col cols="10">
                <div>
                  <p class="main-strong-text mb-0" style="font-size: 12px; line-height: 16px;">
                    {{ timeline.origem }}
                  </p>
                  <p class="main-strong-text mb-0" style="font-size: 10px; font-weight: 400; line-height: 16px;">
                    {{ timeline.datahora }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <TimelineCheck
              :color="timeline.lida ? 'var(--v-tertiary)' : 'rgba(0,0,0,0.2)'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="timeline-body">

          <v-carousel
            :continuous="false"
            :cycle="cycle"
            :show-arrows="false"
            height="320"
            :light="true"
            hide-delimiter-background
          >
            <v-carousel-item
              v-for="(media, i) in timeline.medias"
              :key="i"
            >
              <v-sheet color="black" tile>
                <img
                  v-if="media.type.includes('image')"
                  :src="`data:${media.type};base64, ${media.contents}`"
                  class="timeline-midia"
                  @dblclick="(timeline.likeClick += 1), likeClickEvent(timeline)"
                />
                <video v-if="media.type.includes('video/mp4')" width="100%" height="100%" controls>
                  <source
                    :src="`data:${media.type};base64, ${media.contents}`"
                    :type="media.type"
                  />
                  Your browser does not support the video tag.
                </video>
                <iframe
                  v-if="media.type.includes('video/vimeo')"
                  :src="media.url + '?api=1'"
                  class="timeline-midia" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                />
              </v-sheet>
            </v-carousel-item>
          </v-carousel>

          <div class="interacoes">
            <v-row align="center">
              <v-col class="ml-1" cols="4">
                <v-row align="center">
                  <v-col cols="4">
                    <img
                      style="width: 25px; height: 25px"
                      @click="likeClickEvent(timeline)"
                      :src="timeline.like ? `/img/icons/home/feed-liked.svg` : `/img/icons/home/feed-empty-like.svg`"
                    >
                  </v-col>
                  <v-col class="pl-0 mb-1" cols="8">
                    <p class="main-subtitle mb-0" style="font-size: 16px;">
                      {{ timeline.curtidas }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="1">
                <img
                  style="width: 25px; height: 25px"
                  @click="bookmarkClickEvent(timeline)"
                  :src="timeline.bookmark ? `/img/icons/home/feed-saved.svg` : `/img/icons/home/feed-empty-save.svg`"
                >
              </v-col>
            </v-row>
          </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="timeline-footer" cols="12">
          <p
            class="main-subtitle pl-4 mb-0"
            style="font-size: 14px;"
            v-if="
              (timeline.descricao && timeline.descricao.length <= 150) ||
              timeline.exibirtudo
            "
          >
            {{ timeline.descricao }}
          </p>
          <p
            v-else-if="timeline.descricao"
            class="main-subtitle mb-0"
            style="font-size: 14px;"
          >
            {{ timeline.descricao.substring(0, 149) }}...
            <span class="vermais" @click="vermais(timeline)">Ver Mais</span>
          </p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import TimelineCheck from '../../components/structure/icons/TimelineCheck.vue'

export default {
  name: 'Timeline',
  components: {
    TimelineCheck
  },
  props: {
    timelineItems: {
      type: Array,
      default: () => [],
    },
    cardClass: {
      type: String,
      default: 'mb-3'
    },
    refreshTimeline: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      timelines: [],
      colors: [
        'green',
        'secondary',
        'yellow darken-4',
        'red lighten-2',
        'orange darken-1',
      ],
      cycle: false,
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
    }
  },
  created() {
    this._socialNetworkService = new SocialNetworkService();
  },
  methods: {
    vermais(_timeline) {
      _timeline.exibirtudo = true;
    },
    likeClickEvent(timeline) {
      timeline.curtidas = timeline.like
        ? timeline.curtidas - 1
        : timeline.curtidas + 1;
      timeline.like = !timeline.like;

      setTimeout(() => {
        this.likeEvent(timeline);
      }, 2500);
    },
    likeEvent(timeline) {
      if (timeline.like != timeline.likeOrigem) {
        let getUser = this.$auth.user();

        let feedLike = {
          userId: getUser.id,
          feedItemId: timeline.id,
          id: timeline.likeId,
        };
        this._socialNetworkService
          .SaveLike(feedLike)
          .then((_res) => {
            if (_res.data) {
              if (_res.data.id > 0) {
                timeline.likeId = _res.data.id;
                timeline.like = true;
                timeline.likeOrigem = true;
              } else {
                timeline.likeId = 0;
                timeline.like = false;
                timeline.likeOrigem = false;
              }
            }
          })
          .catch(() => {
          })
          .finally(() => {
          });
      }
    },
    bookmarkClickEvent(timeline) {
      timeline.bookmark = !timeline.bookmark;
      this.bookmarkEvent(timeline);
      // setTimeout(() => {
      // }, 2500);
    },
    viewClickEvent(timeline) {
      if(!timeline.lida) {
        timeline.lida = true;
        // setTimeout(() => {
        //   }, 2500);
        this.viewEvent(timeline);
      }
    },
    bookmarkEvent(timeline) {
      if (timeline.bookmark != timeline.bookmarkOrigem) {
        let getUser = this.$auth.user();

        let feedBookmark = {
          userId: getUser.id,
          feedItemId: timeline.id,
          id: timeline.bookmarkId,
        };
        this._socialNetworkService
          .SaveBookmark(feedBookmark)
          .then((_res) => {
            if (_res.data) {
              if (_res.data.id > 0) {
                timeline.bookmarkId = _res.data.id;
                timeline.bookmark = true;
                timeline.bookmarkOrigem = true;
              } else {
                timeline.bookmarkId = 0;
                timeline.bookmark = false;
                timeline.bookmarkOrigem = false;
              }
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.refreshTimeline()
          });
      }
    },
    viewEvent(timeline) {
      if (timeline.lida != timeline.lidaOrigem) {
        let getUser = this.$auth.user();

        let feedBookmark = {
          userId: getUser.id,
          feedItemId: timeline.id,
          id: 0,
        };
        this._socialNetworkService
          .SaveViewStorie(feedBookmark)
          .then((_res) => {
            if (_res.data) {
              if (_res.data.id > 0) {
                timeline.lida = true;
                timeline.bookmarkOrigem = true;
              } else {
                timeline.lida = timeline.lidaOrigem;
              }
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.updateTotalizersFeed();
            this.refreshTimeline()
          });
      }
    },
    updateTotalizersFeed(){
      this.$emit('updateTotalizersFeed');
    },
  },
  beforeMount() {
    this.timelines = this.timelineItems;
  }
};
</script>

<style scoped>
/* timeline */
.interacoes {
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.interacoes_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeline-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.vermais {
  cursor: pointer;
  font-weight: bold;
}

.timeline-header-name {
  font-weight: bold;
  width: 100%;
}

.timeline-header-subtitle {
  padding-top: 0px;
  margin-top: 0px;
  color: #999 !important;
}

.timeline-header-avatar {
  margin: 10px;
}

.timeline-header-check {
  padding-top: 0px;
  margin-top: -30px !important;
  margin: 10px;
}

.timeline-header-avatar img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: solid 2px var(--v-primary2);
}

.timeline-body img {
  width: 100%;
}

.timeline-footer {
  padding: 15px;
}

.timeline-midia {
  width: 100% !important;
  height: 300px;
}

.timeline-card {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 11px !important;
  max-width: 100%;
  margin-left: 0%;
}
</style>
